input {
	border: none;
	font-size: 1.5em;
	padding: 0.5em;
	width: 100%;
	margin: 0.1em;
	color: #575656;
}
input:focus {
	outline: none;
}
.input__wrapper {
	display: flex;
	border: 2px solid #bcbcbc;
	border-radius: 10px;
	transition: border 500ms ease;
}
.input__wrapper:focus-within {
	border: 2px solid #7d7d7d;
}
.label__todo {
	flex-grow: 1;
}
.btn__add_todo {
	background-color: #08e004;
	padding-inline: 1em;
	outline: none;
	border: none;
	border-radius: 10px;
	margin: 0.2em;
	cursor: pointer;
	display: inline-block;
}
.btn__svg {
	width: 70%;
	height: auto;
}
